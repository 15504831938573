$thumb-size: 180px;
$wrap: 800px;

.citation-container {
  container-type: inline-size;
}

.citation {
  display: flex;
  margin: 20px 0;
  border-radius: var(--rounded);
  background: var(--background);
  overflow: hidden;
  box-shadow: var(--shadow);
}

.citation-image {
  position: relative;
  width: $thumb-size;
  flex-shrink: 0;
  // box-shadow: var(--shadow);
}

.citation-image img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.citation-text {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  height: min-content;
  padding: 20px;
  padding-left: 30px;
  text-align: left;
  overflow-wrap: break-word;
  z-index: 0;
}

.citation-title,
.citation-authors,
.citation-details,
.citation-description {
  width: 100%;
}

.citation-title {
  font-weight: var(--semi-bold);
}

.citation-text > .icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: var(--light-gray);
  opacity: 0.5;
  font-size: 30px;
  z-index: -1;
}

.citation-publisher {
  text-transform: capitalize;
}

.citation-description {
  color: var(--gray);
}

.citation-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.citation-buttons .button {
  margin: 0;
}

.citation-text > .tags {
  display: inline-flex;
  justify-content: flex-start;
  margin: 0;
}

@container (max-width: #{$wrap}) {
  .citation {
    flex-direction: column;
  }

  .citation-image {
    width: unset;
    height: $thumb-size;
  }
}
